<template>
    <div class="home_container">
        <div v-if="openScreen">
            <div class="screen-container">
                <div class="screen-button" @click="closeScreen">
                    <van-count-down
                        :time="6000"
                        format="ss"
                        auto-start
                        style="color: #fff"
                        @finish="openScreen = false"
                    />
                    &nbsp;&nbsp;|&nbsp;&nbsp; 跳过
                </div>
                <van-image
                    width="100%"
                    height="100%"
                    radius="0"
                    :src="openPages.pageImage"
                    @click="openScreenPage"
                />
            </div>
        </div>
        <div v-else>
            <div class="home_banner">
                <van-image
                    width="100%"
                    height="100%"
                    radius="0"
                    :src="bannerPageImage"
                />
            </div>
            <!-- <home-swiper
            imgTag="pageImage"
            :swiperList="firstRotation"
            :slidesPerView="1"
            :spaceBetween="0"
            radio="0"
            width="100%"
        ></home-swiper> -->
            <div class="home_content">
                <home-nav
                    :foodTypes="foodTypes"
                    :imgBaseUrl="imgBaseUrl"
                ></home-nav>
                <div class="u-p-t-40 u-p-b-8">
                    <home-swiper
                        imgTag="pageImage"
                        :swiperList="secondRotation"
                        :slidesPerView="1.3"
                        :spaceBetween="20"
                        webview="pageUrl"
                    ></home-swiper>
                </div>

                <home-card title="人文" subTitle="黄浦" :src="'/humanity'">
                    <home-swiper
                        class="u-m-t-30"
                        :height="150"
                        :slidesPerView="1.2"
                        :spaceBetween="10"
                        imgTag="rwImage"
                        :swiperList="humanisticHuangpu"
                        src="/humanity/details"
                        webview="outUrl"
                    ></home-swiper>
                </home-card>

                <home-card title="行走" subTitle="动态" :src="'/walk'">
                    <home-item
                        class="u-m-t-10"
                        imgTag="rwImage"
                        txtTag="title"
                        :list="walkingDynamics"
                        src="/walk/details"
                        webview="outUrl"
                    ></home-item>
                </home-card>

                <home-card title="为你" subTitle="推荐" :src="'/activity'">
                    <home-item
                        class="u-m-t-10"
                        :list="activities"
                        imgTag="logoUrl"
                        :src="'/activity/details'"
                    >
                        <template v-slot:default="slotProps">
                            <div class="card-content">
                                <header>
                                    {{ slotProps.item.activityTitle }}
                                </header>
                                <figure>
                                    <div class="u-m-t-12">
                                        {{ slotProps.item.actAddress }}
                                    </div>
                                    <div class="u-m-t-12">
                                        {{ slotProps.item.activityHour }}
                                    </div>
                                </figure>
                            </div>
                        </template>
                    </home-item>
                </home-card>

                <home-card title="热门" subTitle="点位" :src="'/point'">
                    <div class="point-container u-m-t-30">
                        <div
                            class="point-item"
                            v-for="(item, index) in linePoints"
                            :key="index"
                            @click="openPage(item.id)"
                        >
                            <van-image
                                width="100%"
                                height="100%"
                                radius="10"
                                :src="item.logoUrl"
                                fit="cover"
                            />
                            <div class="point-title">{{ item.lpTitle }}</div>
                        </div>
                    </div>
                </home-card>

                <home-card
                    title="打卡"
                    subTitle="路线"
                    :src="'/route'"
                    class="point-route"
                >
                    <div class="swiper-container" ref="routeSwiper">
                        <div class="swiper-wrapper">
                            <div
                                class="swiper-slide"
                                v-for="(item, index) in lines"
                                :key="index"
                            >
                                <punch-card
                                    imgTag="logoUrl"
                                    class="u-m-t-10"
                                    :list="[item]"
                                    :src="'/route/details'"
                                ></punch-card>
                            </div>
                        </div>
                    </div>
                </home-card>
            </div>
        </div>
    </div>
</template>

<script>
import { Toast } from "vant";
import homeNav from "./components/home-nav.vue";
import homeSwiper from "./components/swiper.vue";
import homeCard from "./components/home-card.vue";
import homeItem from "./components/home-item.vue";
import punchCard from "@/components/card/punch-card.vue";
import {
    getHomeList,
    loginCallback,
    getWeixinJssdk,
    getPageIndex,
} from "@/service";
export default {
    name: "homePage",
    components: {
        homeNav,
        homeSwiper,
        homeCard,
        homeItem,
        punchCard,
    },
    data() {
        return {
            openPages: {},
            bannerPageImage: "",
            firstRotation: [],
            humanisticHuangpu: [],
            activities: [],
            linePoints: [],
            lines: [],
            openScreen: false,
            secondRotation: [],
            walkingDynamics: [],

            swiperList: ["Slide 1", "Slide 2", "Slide 3"],
            imgBaseUrl: "https://fuss10.elemecdn.com",
            foodTypes: [
                {
                    title: "活动预约",
                    image_url:
                        "http://oss.hkrin.com/userInfo/666/20211022113450yuyue@3x.png",
                    src: "/activity",
                },
                {
                    title: "推荐路线",
                    image_url:
                        "http://oss.hkrin.com/userInfo/666/20211022113942xianlu@3x.png",
                    src: "/route",
                },
                {
                    title: "推荐点位",
                    image_url:
                        "http://oss.hkrin.com/userInfo/666/20211022113848dianwei@3x.png",
                    src: "/point",
                },
                {
                    title: "行走动态",
                    image_url:
                        "http://oss.hkrin.com/userInfo/666/20211022113904dongtai@3x.png",
                    src: "/walk",
                },
                {
                    title: "人文黄浦",
                    image_url:
                        "http://oss.hkrin.com/userInfo/666/20211022113450yuyue@3x.png",
                    src: "/humanity",
                },
                {
                    title: "精彩视频",
                    image_url:
                        "http://oss.hkrin.com/userInfo/666/20211022113925video@3x.png",
                    src: "/video",
                },
            ],
        };
    },
    watch: {
        lines: function (value) {
            if (value.length > 0) {
                setTimeout(() => {
                    new Swiper(this.$refs.routeSwiper, {
                        slidesPerView: 1.1,
                        spaceBetween: 20,
                        centeredSlides: true,
                        loop: true,
                        touchRatio: 2,
                    });
                }, 800);
            }
        },
    },
    created() {
        const openScreen = localStorage.getItem("openScreen");
        if (!openScreen) {
            this.openScreen = true;
            localStorage.setItem("openScreen", true);
        }
    },
    async mounted() {
        const { code = "" } = this.$route.query;
        if (code) {
            await this.initToken(code);
        }
        this.getPageIndex();
        this.getHomeList();
    },
    methods: {
        // /app/pageIndex
        async getPageIndex() {
            const { data } = await getPageIndex({}, false);
            this.openPages = data;
        },
        async initToken(code) {
            const { data } = await loginCallback({ code: code }, false);
            sessionStorage.setItem("xxl_sso_sessionid", data.sessionId);
        },
        async initWechat() {
            /*
             * 注意：
             * 1. 所有的JS接口只能在公众号绑定的域名下调用，公众号开发者需要先登录微信公众平台进入“公众号设置”的“功能设置”里填写“JS接口安全域名”。
             * 2. 如果发现在 Android 不能分享自定义内容，请到官网下载最新的包覆盖安装，Android 自定义分享接口需升级至 6.0.2.58 版本及以上。
             * 3. 常见问题及完整 JS-SDK 文档地址：http://mp.weixin.qq.com/wiki/7/aaa137b55fb2e0456bf8dd9148dd613f.html
             *
             * 开发中遇到问题详见文档“附录5-常见错误及解决办法”解决，如仍未能解决可通过以下渠道反馈：
             * 邮箱地址：weixin-open@qq.com
             * 邮件主题：【微信JS-SDK反馈】具体问题
             * 邮件内容说明：用简明的语言描述问题所在，并交代清楚遇到该问题的场景，可附上截屏图片，微信团队会尽快处理你的反馈。
             */

            const { nonceStr, jsapi_ticket, signature, timestamp } =
                await getWeixinJssdk({ url: window.location.href });
            if (nonceStr) {
                wx.config({
                    debug: false,
                    appId: "wx5a5c6ad0371692ec",
                    timestamp: timestamp,
                    nonceStr: nonceStr,
                    signature: signature,
                    jsApiList: ["checkJsApi", "openLocation", "getLocation"],
                });
            }
        },
        async getHomeList() {
            try {
                /* Toast.loading({
                    message: "加载中...",
                    forbidClick: true,
                }); */
                const { data } = await getHomeList();
                this.firstRotation = data.firstRotation || [];
                this.humanisticHuangpu = data.humanisticHuangpu || [];
                this.activities = data.activities || [];
                this.linePoints = data.linePoints || [];
                this.lines = data.lines || [];
                this.secondRotation = data.secondRotation || [];
                this.walkingDynamics = data.walkingDynamics || [];
                this.bannerPageImage = data.firstRotation[0].pageImage;
            } catch (err) {
            } finally {
                // Toast.clear();
            }
        },
        openPage(id) {
            this.$router.push(`/point/details?id=${id}`);
        },
        closeScreen() {
            this.openScreen = false;
        },
        openScreenPage() {
            window.location.href = this.openPages.pageUrl;
        },
        isIOS() {
            var u = navigator.userAgent;
            let isIphone = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
            return isIphone;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/mixin";
@import "@/style/_varibles";

.home_container {
    background: #fff;
    padding-bottom: 120px;
    .home_banner {
        @include wh(100%, 370px);
    }
    .card-content {
        header {
            @include sc(20px, $--color-text-regular);
        }
        figure {
            @include sc(16px, $--color-text-secondary);
            line-height: 22px;
        }
    }
    .point-container {
        @include fj(space-between);
        padding: 0 36px;

        .point-item {
            box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.17);
            flex: 1;
            margin-left: 20px;
            height: 300px;
            border-radius: 20px;
            position: relative;
            &:first-child {
                margin-left: 0;
            }
            .point-title {
                @include sc(28px, #fff);
                position: absolute;
                bottom: 16px;
                left: 0;
                right: 0;
                text-align: center;
            }
        }
    }
    .point-route {
        .swiper-container {
            width: 100%;
            height: auto;
        }
        .swiper-slide {
            width: 100%;
            height: 100%;
            border-radius: 20px;
            text-align: center;
            transition: 300ms;
            transform: scale(1);
            padding-bottom: 15px;
        }
        .swiper-slide-active,
        .swiper-slide-duplicate-active {
            transform: scale(1);
        }
    }
    .screen-container {
        background: #fff;
        position: fixed;
        z-index: 1000;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        .screen-button {
            position: absolute;
            top: 50px;
            right: 30px;
            // width: 100px;
            padding: 0 20px;
            height: 50px;
            font-size: 24px;
            color: #fff;
            background: rgb(170, 170, 170);
            z-index: 10;
            text-align: center;
            line-height: 50px;
            border-radius: 20px;
            display: flex;
            align-items: center;
            flex-direction: row;
            line-height: normal;
        }
    }
}
</style>