<template>
    <div class="home-card">
        <header>
            <div class="header-title">
                <span class="title">{{ title }}</span>
                <span class="title-main"
                    >「<span class="bold">{{ subTitle }}</span
                    >」</span
                >
            </div>
            <router-link :to="src" v-if="src">
                <div class="title-more">查看更多 ></div>
            </router-link>
        </header>
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: ["title", "subTitle", "src"],
    name: "home-card",
    data() {
        return {};
    },
    methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/style/_varibles";
@import "@/style/mixin";
.home-card {
    padding: 50px 0 0;
    header {
        padding: 0 36px;
        @include sc(36px, $--color-text-regular);
        @include fj(space-between);
        align-items: center;
        .header-title {
            .title {
                font-weight: 800;
            }
            .title-main {
                color: $--color-text-primary;
                .bold {
                    @include sc(48px, $--color-text-primary);
                    font-weight: 800;
                }
            }
        }
        .title-more {
            @include sc(24px, $--color-text-secondary);
        }
    }
}
</style>