<template>
    <nav class="msite_nav">
        <div class="swiper-container" v-if="foodTypes.length">
            <div class="food_types_container">
                <div
                    v-for="foodItem in foodTypes"
                    :key="foodItem.id"
                    class="link_to_food"
                >
                    <router-link :to="`${foodItem.src}`">
                        <figure>
                            <!-- <img :src="imgBaseUrl + foodItem.image_url" /> -->
                            <van-icon :name="foodItem.image_url" color="#B72E32" size="24" />
                            <figcaption>{{ foodItem.title }}</figcaption>
                        </figure>
                    </router-link>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
// import "@/plugins/swiper.min.js";
// import "@/style/swiper.min.css";

export default {
    props: {
        foodTypes: {
            type: Array,
            default: [],
        },
        imgBaseUrl: {
            type: String,
            default: "",
        },
    },
    components: {},
    data() {
        return {};
    },
    methods: {
        getCategoryId() {},
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/_varibles";
@import "@/style/mixin";

.msite_nav {
    background-color: #fff;
    .swiper-container {
        @include wh(100%, 320px);
    }
}
.food_types_container {
    @include wh(100%, 320px);
    display: flex;
    flex-wrap: wrap;
    border-radius: 12px 12px 0 0;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.17);
    overflow: hidden;
    background: #fff;
    padding: 0 0 40px;
    .link_to_food {
        width: 33%;
        @include fj(center);
        text-align: center;
        padding-top: 40px;
        figure {
            img {
                @include wh(44px, 44px);
            }
            figcaption {
                @include sc(24px, #231f20);
                overflow-wrap: break-word;
                white-space: nowrap;
                line-height: 24px;
                margin-top: 20px;
            }
        }
    }
}
</style>