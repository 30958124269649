<template>
    <div class="swiper-container" ref="swiper">
        <div class="swiper-wrapper">
            <div
                class="swiper-slide"
                :style="{ height: `${height}px`, width: `${width}` }"
                v-for="(item, index) in swiperList"
                :key="index"
                @click="openPage(item.id, item[webview] || '')"
            >
                <van-image
                    width="100%"
                    height="100%"
                    :radius="radio || 10"
                    :src="item[imgTag]"
                    fit="cover"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "home-swiper",
    props: [
        "imgTag",
        "height",
        "width",
        "slidesPerView",
        "spaceBetween",
        "swiperList",
        "radio",
        "src",
        "webview",
    ],
    watch: {
        swiperList: {
            immediate: true,
            handler: function (newVal) {
                if (newVal.length > 0) {
                    setTimeout(() => {
                        new Swiper(this.$refs.swiper, {
                            slidesPerView: Number(this.slidesPerView || 1),
                            spaceBetween: Number(this.spaceBetween || 0),
                            centeredSlides: true,
                            loop: true,
                            touchRatio: 2,
                        });
                    }, 500);
                }
            },
        },
    },
    mounted() {},
    methods: {
        openPage(id, webview) {
            if (webview.includes("http")) {
                sessionStorage.setItem("webview", webview);
                window.location.href = webview;
                // this.$nextTick(() => {
                //     this.$router.push(`/webview?id=${id}`);
                // });
                return false;
            }
            this.$router.push(`${this.src}?id=${id}`);
        },
    },
};
</script>

<style lang="scss" scoped>
.swiper-container {
    width: 100%;
    height: auto;
}
.swiper-slide {
    width: 630px;
    height: 240px;
    border-radius: 20px;
    text-align: center;
    background: rgb(141, 179, 70);
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    transition: 300ms;
    transform: scale(0.8);
}
.swiper-slide-active,
.swiper-slide-duplicate-active {
    transform: scale(1);
}
</style>